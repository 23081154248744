import React from "react"
import s from "./app.module.scss"
import { graphql } from "gatsby"
import Img from "../components/common/Img"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import {
  selectAppPageContent,
  selectProductGroups,
  selectTranslations,
} from "../graphql/selectors"
import classNames from "classnames"

export default function AppPage(props) {
  const { data, pageContext } = props
  const { langCode, localePath, pagePathsByLangCode } = pageContext
  const translations = selectTranslations(data)
  const {
    content: { appInfo, downloadInfo, hero, images },
    seo,
  } = selectAppPageContent(data)

  return (
    <Layout
      className={s.feedbackSuccessPage}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...seo} lang={langCode} />
      <div className={s.hero}>
        <h1 className={s.heroText}>
          {hero.title.partFirst}
          <br />
          <span
            className={
              langCode === "lt" || (langCode === "lv" ? s.heroTextAdjusted: undefined)
            }
          >
            {hero.title.partSecond}
            <img
              alt="bk-crown"
              className={
                langCode === "en" || langCode === "ru"
                  ? s.heroImg
                  : classNames(s.heroImg, s.heroImgAdjusted)
              }
              src="/images/pages/app/bk-crown.svg"
            />
          </span>
        </h1>
      </div>
      <section className={s.appContent}>
        <div className={s.appInfo}>
          <h2 className={s.appInfoHeading}>{appInfo.title}</h2>
          <div className={s.appDownloadContainer}>
            <p className={s.appDownloadContainerHeading}>
              {downloadInfo.title}
            </p>
            <div className={s.buttonsContainer}>
              <a
                href={downloadInfo.linkApple}
                role="button"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Img
                  alt={images.downloadApple.imgAlt}
                  src={images.downloadApple.imgSrc}
                />
              </a>
              <a
                href={downloadInfo.linkGoogle}
                rel="noopener noreferrer"
                role="button"
                target="_blank"
              >
                <Img
                  alt={images.downloadGoogle.imgAlt}
                  src={images.downloadGoogle.imgSrc}
                />
              </a>
            </div>
          </div>
          <div className={s.appInfoItems}>
            {appInfo.items.map((item, index) => {
              const { imgAlt, imgSrc, title, description } = item

              return (
                <article className={s.appInfoItem} key={index}>
                  <div className={s.appInfoItemImgAndText}>
                    <img alt={imgAlt} src={imgSrc} />
                    <h3>{title}</h3>
                  </div>
                  <div className={s.appInfoItemDescription}>
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                </article>
              )
            })}
          </div>
          <p className={s.appDownloadContainerDescription}>
            {downloadInfo.description}{" "}
            <a
              href={downloadInfo.linkOrderHref}
              rel="noopener noreferrer"
              target="_blank"
            >
              {downloadInfo.linkOrder}
            </a>
            .
          </p>
        </div>
      </section>
      <div className={s.appImgContainer}>
        <Img
          alt={images.mobileApp.imgAlt}
          className={s.image}
          src={images.mobileApp.image}
        />
        <Img
          alt="mobile-app"
          className={s.imageXs}
          src={images.mobileApp.imageXs}
        />
        <Img
          alt="mobile-app"
          className={s.imageMd}
          src={images.mobileApp.imageMd}
        />
        <Img
          alt="mobile-app"
          className={s.imageLg}
          src={images.mobileApp.imageLg}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AppPage($locale: String) {
    ...LayoutFragment
    ...AppPageFragment
  }
`
